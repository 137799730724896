import {Link} from 'gatsby'
import React, {useEffect} from "react"
import Seo from '../components/Seo'
import ContactForm from '../components/ContactForm'
import SectionTitle from '../components/SectionTitle'
import Layout from "../components/Layout"
import Pricing from "../components/Pricing"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileCode, faImage, faGear, faRocket } from '@fortawesome/free-solid-svg-icons'
import SvgFooter from '../components/SvgFooter'
import CtaSection from '../components/CtaSection'
import Portfolio from '../components/Portfolio'

export default function Home() {
  const headerSvgAnimation = (event, img) => {
    const manHead = img.querySelector('#head');
    let mousePosition = event.clientY;
    const angle = mousePosition / 60;
  
    manHead.style.transform = `rotateZ(-${angle}deg)`;
    img.style.transform = `translate(${event.clientX / 60}px, ${angle}px)`;
  }
  
  useEffect(() => {
    const headerImg = document.querySelector('#headerImg');
    if(headerImg) {
      document.querySelector('section#start').addEventListener('mousemove', e => headerSvgAnimation(e, headerImg));
    }
  }, []);

  return (
    <Layout>
      <Seo title="Nowoczesne strony internetowe" description="Freelancer, grafik, programista z Bydgoszczy. Projektuję i tworzę strony internetowe. Zapewniam wysoką jakość i niskie ceny! Zapraszam do kontaktu." />
      <section id="start" className="section">

        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7 pt-3 pt-lg-0 d-flex flex-column justify-content-center">
              <h1 className="page-title mb-4 text-uppercase">Tworzę strony internetowe</h1>
              <ul className="list-unstyled d-flex gap-2 mb-3 flex-wrap">
                <li><span className="badge bg-secondary">Programista</span></li>
                <li><span className="badge bg-secondary">Web Developer</span></li>
                <li><span className="badge bg-secondary">Freelancer</span></li>
              </ul>
              <p>
                Cześć! Nazywam się Maciej Tymosiak i na co dzień współtworzę portal Przymorzu.pl, a po godzinach pracuję jako <strong>freelancer</strong> i chętnie <strong>zrobię dla Ciebie stronę internetową</strong>.
              </p>
              <p className="mb-0">Tworzę profesjonalne strony internetowe dla firm z Bydgoszczy i całej Polski. Z chęcią porozmawiam z Tobą o projekcie Twojej witryny i wykonam bezpłatną wycenę.</p>
              <div className="mt-3 d-flex flex-wrap gap-3">
                <Link to="/brief" className="btn btn-secondary btn--with-icon" title="Bezpłatna wycena strony internetowej">
                  <FontAwesomeIcon icon={faRocket} />
                  Chcę stronę WWW
                </Link>
                <Link to="/oferta" className="btn btn-secondary" title="Oferta stworzenia strony internetowej">Oferta</Link>
              </div>
            </div>

            <div className="col-lg-5 d-none d-lg-flex" data-aos="zoom-in" data-aos-delay="150">
              <svg id="headerImg" className="img-fluid" width="693" height="528" viewBox="0 0 693 528" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_8_2)">
                <rect id="background" width="693" height="528" rx="23" fill="#FDFDFD"/>
                <path id="Vector" d="M693 283.5V505.31C692.993 511.326 690.6 517.093 686.346 521.346C682.093 525.6 676.326 527.993 670.31 528H500.17L501.75 526L691 286.04L693 283.5Z" fill="#EDEDED"/>
                <g id="content">
                <path id="Vector_2" d="M240.646 172H67.3539C65.934 172 64.5722 172.632 63.5681 173.757C62.5641 174.883 62 176.409 62 178C62 179.591 62.5641 181.117 63.5681 182.243C64.5722 183.368 65.934 184 67.3539 184H240.646C242.066 184 243.428 183.368 244.432 182.243C245.436 181.117 246 179.591 246 178C246 176.409 245.436 174.883 244.432 173.757C243.428 172.632 242.066 172 240.646 172Z" fill="#EDEDED"/>
                <path id="Vector_3" d="M268.802 197H68.1977C66.554 197 64.9776 197.632 63.8153 198.757C62.653 199.883 62 201.409 62 203C62 204.591 62.653 206.117 63.8153 207.243C64.9776 208.368 66.554 209 68.1977 209H268.802C270.446 209 272.022 208.368 273.185 207.243C274.347 206.117 275 204.591 275 203C275 201.409 274.347 199.883 273.185 198.757C272.022 197.632 270.446 197 268.802 197Z" fill="#EDEDED"/>
                <path id="Vector_4" d="M302.784 222H69.2161C67.3023 222 65.4668 222.632 64.1135 223.757C62.7602 224.883 62 226.409 62 228C62 229.591 62.7602 231.117 64.1135 232.243C65.4668 233.368 67.3023 234 69.2161 234H302.784C304.698 234 306.533 233.368 307.886 232.243C309.24 231.117 310 229.591 310 228C310 226.409 309.24 224.883 307.886 223.757C306.533 222.632 304.698 222 302.784 222Z" fill="#EDEDED"/>
                <path id="Vector_5" d="M172.946 147H72.0535C69.3872 147 66.83 147.632 64.9446 148.757C63.0592 149.883 62 151.409 62 153C62 154.591 63.0592 156.117 64.9446 157.243C66.83 158.368 69.3872 159 72.0535 159H172.946C175.613 159 178.17 158.368 180.055 157.243C181.941 156.117 183 154.591 183 153C183 151.409 181.941 149.883 180.055 148.757C178.17 147.632 175.613 147 172.946 147H172.946Z" fill="#EDEDED"/>
                <path id="Vector_6" d="M108.846 247H67.1543C66.0525 247 64.9959 247.79 64.2168 249.197C63.4377 250.603 63 252.511 63 254.5C63 256.489 63.4377 258.397 64.2168 259.803C64.9959 261.21 66.0525 262 67.1543 262H108.846C109.947 262 111.004 261.21 111.783 259.803C112.562 258.397 113 256.489 113 254.5C113 252.511 112.562 250.603 111.783 249.197C111.004 247.79 109.947 247 108.846 247H108.846Z" fill="#EE3838"/>
                <path id="Vector_7" d="M168.846 247H127.154C126.053 247 124.996 247.79 124.217 249.197C123.438 250.603 123 252.511 123 254.5C123 256.489 123.438 258.397 124.217 259.803C124.996 261.21 126.053 262 127.154 262H168.846C169.947 262 171.004 261.21 171.783 259.803C172.562 258.397 173 256.489 173 254.5C173 252.511 172.562 250.603 171.783 249.197C171.004 247.79 169.947 247 168.846 247Z" fill="#EE3838"/>
                </g>
                <path id="Vector_8" d="M670.31 0H22.69C16.6745 0.0072432 10.9074 2.40012 6.65375 6.65375C2.40012 10.9074 0.0072432 16.6745 0 22.69V505.31C0.0072432 511.326 2.40012 517.093 6.65375 521.346C10.9074 525.6 16.6745 527.993 22.69 528H670.31C676.326 527.993 682.093 525.6 686.346 521.346C690.6 517.093 692.993 511.326 693 505.31V22.69C692.993 16.6745 690.6 10.9074 686.346 6.65375C682.093 2.40012 676.326 0.0072432 670.31 0ZM691 505.31C691 508.027 690.465 510.717 689.425 513.228C688.385 515.738 686.861 518.019 684.94 519.94C683.019 521.861 680.738 523.385 678.228 524.425C675.717 525.465 673.027 526 670.31 526H22.69C17.2027 526 11.9401 523.82 8.05997 519.94C4.17984 516.06 2 510.797 2 505.31V157.676C2 116.388 7.80517 64.6948 37 35.5C66.1948 6.30517 116.388 2 157.676 2H670.31C673.027 2 675.717 2.53517 678.228 3.57494C680.738 4.61471 683.019 6.13872 684.94 8.05997C686.861 9.98121 688.385 12.2621 689.425 14.7723C690.465 17.2825 691 19.973 691 22.69V505.31Z" fill="#EEEEEE"/>
                <path id="Vector_9" d="M629.89 87.2685H63.16C62.8948 87.2685 62.6404 87.1632 62.4529 86.9756C62.2653 86.7881 62.16 86.5337 62.16 86.2685C62.16 86.0033 62.2653 85.749 62.4529 85.5614C62.6404 85.3739 62.8948 85.2685 63.16 85.2685H629.89C630.156 85.2685 630.41 85.3739 630.598 85.5614C630.785 85.749 630.89 86.0033 630.89 86.2685C630.89 86.5337 630.785 86.7881 630.598 86.9756C630.41 87.1632 630.156 87.2685 629.89 87.2685Z" fill="#EDEDED"/>
                <g id="Group 3">
                <path id="Vector_10" d="M630.289 41H607.711C607.522 41 607.341 41.158 607.208 41.4393C607.075 41.7206 607 42.1022 607 42.5C607 42.8978 607.075 43.2794 607.208 43.5607C607.341 43.842 607.522 44 607.711 44H630.289C630.478 44 630.659 43.842 630.792 43.5607C630.925 43.2794 631 42.8978 631 42.5C631 42.1022 630.925 41.7206 630.792 41.4393C630.659 41.158 630.478 41 630.289 41V41Z" fill="#EEEEEE"/>
                <path id="Vector_11" d="M630.289 47H607.711C607.522 47 607.341 47.158 607.208 47.4393C607.075 47.7206 607 48.1022 607 48.5C607 48.8978 607.075 49.2794 607.208 49.5607C607.341 49.842 607.522 50 607.711 50H630.289C630.478 50 630.659 49.842 630.792 49.5607C630.925 49.2794 631 48.8978 631 48.5C631 48.1022 630.925 47.7206 630.792 47.4393C630.659 47.158 630.478 47 630.289 47V47Z" fill="#EEEEEE"/>
                <path id="Vector_12" d="M630.289 54H607.711C607.522 54 607.341 54.158 607.208 54.4393C607.075 54.7206 607 55.1022 607 55.5C607 55.8978 607.075 56.2794 607.208 56.5607C607.341 56.842 607.522 57 607.711 57H630.289C630.478 57 630.659 56.842 630.792 56.5607C630.925 56.2794 631 55.8978 631 55.5C631 55.1022 630.925 54.7206 630.792 54.4393C630.659 54.158 630.478 54 630.289 54V54Z" fill="#EEEEEE"/>
                </g>
                <rect id="image" x="347" y="132" width="284" height="140" rx="12" fill="#EDEDED"/>
                <g id="Group 2">
                <path id="Vector_13" d="M238.205 473.806C238.002 473.806 237.801 473.766 237.615 473.687C237.428 473.608 237.259 473.493 237.117 473.348L228.434 464.472C228.156 464.187 228 463.806 228 463.408C228 463.01 228.156 462.628 228.434 462.344L237.117 453.468C237.256 453.323 237.423 453.207 237.607 453.127C237.792 453.046 237.99 453.003 238.191 453C238.392 452.997 238.592 453.034 238.779 453.108C238.965 453.183 239.136 453.293 239.279 453.434C239.423 453.574 239.538 453.742 239.616 453.927C239.695 454.112 239.736 454.311 239.737 454.512C239.738 454.713 239.7 454.913 239.623 455.099C239.547 455.285 239.435 455.454 239.293 455.596L231.651 463.408L239.293 471.22C239.502 471.434 239.644 471.705 239.7 471.999C239.756 472.294 239.724 472.598 239.607 472.874C239.491 473.15 239.296 473.386 239.047 473.551C238.797 473.717 238.504 473.806 238.205 473.806V473.806Z" fill="#EEEEEE"/>
                <path id="Vector_14" d="M252.796 473.806C252.496 473.806 252.203 473.717 251.954 473.551C251.704 473.386 251.509 473.15 251.393 472.874C251.277 472.598 251.245 472.294 251.301 471.999C251.357 471.705 251.498 471.434 251.708 471.22L259.349 463.408L251.708 455.596C251.43 455.307 251.278 454.92 251.285 454.519C251.291 454.118 251.455 453.735 251.742 453.455C252.029 453.175 252.414 453.019 252.815 453.021C253.216 453.024 253.6 453.184 253.883 453.468L262.566 462.344C262.844 462.628 263 463.01 263 463.408C263 463.805 262.844 464.187 262.566 464.472L253.883 473.348C253.742 473.493 253.573 473.608 253.386 473.687C253.199 473.765 252.998 473.806 252.796 473.806V473.806Z" fill="#EEEEEE"/>
                </g>
                <g id="articles">
                <g id="item">
                <rect id="Rectangle 3" x="62" y="360" width="106.875" height="75" rx="12" fill="#EDEDED"/>
                <g id="Group 5">
                <path id="Vector_15" d="M121.347 375H74.6529C73.4188 375 72.2354 375.527 71.3628 376.464C70.4902 377.402 70 378.674 70 380C70 381.326 70.4902 382.598 71.3628 383.536C72.2354 384.473 73.4188 385 74.6529 385H121.347C122.581 385 123.765 384.473 124.637 383.536C125.51 382.598 126 381.326 126 380C126 378.674 125.51 377.402 124.637 376.464C123.765 375.527 122.581 375 121.347 375Z" fill="#D3D3D3"/>
                <path id="Vector_16" d="M97.5074 393H72.4926C71.8315 393 71.1975 393.527 70.7301 394.464C70.2626 395.402 70 396.674 70 398C70 399.326 70.2626 400.598 70.7301 401.536C71.1975 402.473 71.8315 403 72.4926 403H97.5074C98.1685 403 98.8025 402.473 99.2699 401.536C99.7374 400.598 100 399.326 100 398C100 396.674 99.7374 395.402 99.2699 394.464C98.8025 393.527 98.1684 393 97.5074 393Z" fill="#D3D3D3"/>
                <path id="Vector_17" d="M156.507 414H131.493C130.832 414 130.198 414.527 129.73 415.464C129.263 416.402 129 417.674 129 419C129 420.326 129.263 421.598 129.73 422.536C130.198 423.473 130.832 424 131.493 424H156.507C157.168 424 157.802 423.473 158.27 422.536C158.737 421.598 159 420.326 159 419C159 417.674 158.737 416.402 158.27 415.464C157.802 414.527 157.168 414 156.507 414Z" fill="#EE3838"/>
                </g>
                </g>
                <g id="item_2">
                <rect id="Rectangle 3_2" x="322" y="361" width="106.875" height="75" rx="12" fill="#EDEDED"/>
                <g id="Group 5_2">
                <path id="Vector_18" d="M381.347 376H334.653C333.419 376 332.235 376.527 331.363 377.464C330.49 378.402 330 379.674 330 381C330 382.326 330.49 383.598 331.363 384.536C332.235 385.473 333.419 386 334.653 386H381.347C382.581 386 383.765 385.473 384.637 384.536C385.51 383.598 386 382.326 386 381C386 379.674 385.51 378.402 384.637 377.464C383.765 376.527 382.581 376 381.347 376Z" fill="#D3D3D3"/>
                <path id="Vector_19" d="M357.507 394H332.493C331.832 394 331.198 394.527 330.73 395.464C330.263 396.402 330 397.674 330 399C330 400.326 330.263 401.598 330.73 402.536C331.198 403.473 331.832 404 332.493 404H357.507C358.168 404 358.802 403.473 359.27 402.536C359.737 401.598 360 400.326 360 399C360 397.674 359.737 396.402 359.27 395.464C358.802 394.527 358.168 394 357.507 394Z" fill="#D3D3D3"/>
                <path id="Vector_20" d="M416.507 415H391.493C390.832 415 390.198 415.527 389.73 416.464C389.263 417.402 389 418.674 389 420C389 421.326 389.263 422.598 389.73 423.536C390.198 424.473 390.832 425 391.493 425H416.507C417.168 425 417.802 424.473 418.27 423.536C418.737 422.598 419 421.326 419 420C419 418.674 418.737 417.402 418.27 416.464C417.802 415.527 417.168 415 416.507 415Z" fill="#EE3838"/>
                </g>
                </g>
                <g id="item_3">
                <rect id="Rectangle 3_3" x="192" y="361" width="106.875" height="75" rx="12" fill="#EDEDED"/>
                <g id="Group 5_3">
                <path id="Vector_21" d="M251.347 376H204.653C203.419 376 202.235 376.527 201.363 377.464C200.49 378.402 200 379.674 200 381C200 382.326 200.49 383.598 201.363 384.536C202.235 385.473 203.419 386 204.653 386H251.347C252.581 386 253.765 385.473 254.637 384.536C255.51 383.598 256 382.326 256 381C256 379.674 255.51 378.402 254.637 377.464C253.765 376.527 252.581 376 251.347 376Z" fill="#D3D3D3"/>
                <path id="Vector_22" d="M227.507 394H202.493C201.832 394 201.198 394.527 200.73 395.464C200.263 396.402 200 397.674 200 399C200 400.326 200.263 401.598 200.73 402.536C201.198 403.473 201.832 404 202.493 404H227.507C228.168 404 228.802 403.473 229.27 402.536C229.737 401.598 230 400.326 230 399C230 397.674 229.737 396.402 229.27 395.464C228.802 394.527 228.168 394 227.507 394Z" fill="#D3D3D3"/>
                <path id="Vector_23" d="M286.507 415H261.493C260.832 415 260.198 415.527 259.73 416.464C259.263 417.402 259 418.674 259 420C259 421.326 259.263 422.598 259.73 423.536C260.198 424.473 260.832 425 261.493 425H286.507C287.168 425 287.802 424.473 288.27 423.536C288.737 422.598 289 421.326 289 420C289 418.674 288.737 417.402 288.27 416.464C287.802 415.527 287.168 415 286.507 415Z" fill="#EE3838"/>
                </g>
                </g>
                </g>
                <g id="man">
                <path id="Vector_24" d="M569.392 517.568L559.491 517.567L554.781 479.377L569.394 479.378L569.392 517.568Z" fill="#FFB6B6"/>
                <path id="Vector_25" d="M569.741 527.897L539.294 527.896V527.511C539.294 524.368 540.543 521.353 542.765 519.131C544.987 516.909 548.002 515.66 551.144 515.66H551.145L556.707 511.441L567.083 515.661L569.741 515.661L569.741 527.897Z" fill="#2F2E41"/>
                <path id="Vector_26" d="M604.392 517.568L594.491 517.567L589.781 479.377L604.394 479.378L604.392 517.568Z" fill="#FFB6B6"/>
                <path id="Vector_27" d="M604.741 527.897L574.294 527.896V527.511C574.294 524.368 575.543 521.353 577.765 519.131C579.987 516.909 583.002 515.66 586.144 515.66H586.145L591.707 511.441L602.083 515.661L604.741 515.661L604.741 527.897Z" fill="#2F2E41"/>
                <path id="Vector_28" d="M544.739 309.48C544.739 309.48 535.213 338.738 536.972 342.589C538.73 346.44 541.73 343.44 538.73 346.44C535.73 349.44 533.111 346.251 535.921 351.346C538.73 356.44 542.986 398.38 544.858 400.41C546.73 402.44 549.304 402.494 547.017 404.467C544.73 406.44 541.73 402.44 544.73 406.44C547.73 410.44 555.691 502.716 555.691 502.716H571.242C571.242 502.716 575.115 498.251 572.922 495.346C570.73 492.44 573.115 491.32 572.922 489.38C572.73 487.44 573.459 483.257 573.595 481.349C573.73 479.44 576.358 433.971 570.044 407.706L581.558 357.693L587.314 418.14C587.314 418.14 580.899 468.842 587.314 485.141C587.314 485.141 582.142 489.448 584.436 491.944C586.73 494.44 588.73 492.44 586.73 494.44C584.73 496.44 589.73 503.44 589.73 503.44L606.73 502.44L616.352 407.706C616.352 407.706 617.974 397.826 616.352 396.133C614.73 394.44 614.73 400.44 614.73 394.44V386.44C614.73 386.44 621.981 337.235 621.855 334.338C621.73 331.44 609.73 310.44 609.73 310.44L544.739 309.48Z" fill="#33314D"/>
                <path id="Vector_29" d="M595.019 191.147L570.196 189.44L562.768 201.152L544.88 208.425V266.119C546.741 271.706 547.703 277.552 547.73 283.44C547.662 294.935 544.707 308.475 545.73 311.44C555.73 340.44 610.73 311.44 610.73 311.44L609.73 280.44L611.087 210.381L599.848 201.417L595.019 191.147Z" fill="#2438B1"/>
                <g id="leftArm">
                <path id="Vector_30" d="M543.733 322.005L542.021 303.763L531.464 303.287L533.96 322.509C533.32 323.453 532.956 324.557 532.909 325.697C532.862 326.837 533.134 327.968 533.694 328.962C534.254 329.956 535.08 330.774 536.08 331.324C537.08 331.874 538.213 332.134 539.353 332.075C540.492 332.016 541.592 331.641 542.53 330.991C543.468 330.341 544.206 329.442 544.661 328.396C545.116 327.35 545.27 326.197 545.106 325.068C544.942 323.939 544.466 322.878 543.732 322.005L543.733 322.005Z" fill="#FFB6B6"/>
                <path id="Vector_31" d="M548.928 206.886L548.635 206.936C545.913 207.397 543.344 208.515 541.152 210.193C538.959 211.871 537.208 214.058 536.051 216.564C533.027 223.102 523.211 266.081 527.514 272.562C530.852 277.589 530.041 276.609 528.472 280.4C526.903 284.192 524.376 280.146 526.447 286.164C528.518 292.182 530.794 291.323 528.871 293.649C526.949 295.974 524.977 295.518 526.847 299.412C528.716 303.307 528.412 304.621 528.766 306.088C529.119 307.554 528.812 317.869 528.812 317.869C528.812 317.869 540.088 317.014 545.7 319.696C545.7 319.696 542.569 309.278 544.339 307.61C546.109 305.942 548.233 305.741 546.717 303.313C545.201 300.886 541.763 300.783 545.505 299.571C549.247 298.359 548.042 276.615 548.042 276.615C554.205 260.475 551.77 233.927 548.928 206.886Z" fill="#2438B1"/>
                </g>
                <g id="rightArm">
                <path id="Vector_32" d="M616.922 323.306L617.711 305.002L628.231 303.994L626.708 323.317C627.395 324.228 627.814 325.312 627.919 326.448C628.023 327.585 627.809 328.727 627.299 329.748C626.79 330.769 626.006 331.628 625.035 332.227C624.064 332.827 622.946 333.144 621.805 333.143C620.664 333.142 619.546 332.822 618.576 332.221C617.607 331.619 616.825 330.759 616.317 329.737C615.81 328.715 615.598 327.571 615.705 326.435C615.812 325.3 616.233 324.216 616.922 323.306V323.306Z" fill="#FFB6B6"/>
                <path id="Vector_33" d="M605.926 208.597L606.221 208.632C608.963 208.955 611.585 209.942 613.859 211.507C616.134 213.072 617.993 215.168 619.275 217.613C622.624 223.99 633.22 267.182 630.626 273.109C628.206 278.637 628.306 277.277 630.065 280.985C631.823 284.693 634.142 280.525 632.378 286.639C630.613 292.754 628.297 292.011 630.334 294.237C632.371 296.462 634.318 295.907 632.647 299.891C630.976 303.875 631.346 305.172 631.067 306.655C630.788 308.137 631.616 318.424 631.616 318.424C631.616 318.424 620.31 318.138 614.841 321.1C614.841 321.1 617.443 310.538 615.59 308.961C613.738 307.385 611.607 307.29 612.999 304.789C614.39 302.288 617.819 302.013 614.02 300.991C610.222 299.969 610.329 278.192 610.329 278.192L605.926 208.597Z" fill="#2438B1"/>
                </g>
                <g id="head">
                <path id="Vector_34" d="M583.217 185.773C593.911 185.773 602.579 177.104 602.579 166.41C602.579 155.717 593.911 147.048 583.217 147.048C572.524 147.048 563.855 155.717 563.855 166.41C563.855 177.104 572.524 185.773 583.217 185.773Z" fill="#FFB6B6"/>
                <path id="Vector_35" d="M596.692 182.984C599.385 180.877 599.646 176.86 601.944 174.327C605.786 170.094 607.591 165.539 605.579 160.522C603.799 156.085 604.177 150.631 600.343 147.775C602.226 147.093 602.669 145.678 601.008 145.651C599.887 145.634 595.71 147.775 597.72 145.108C599.73 142.44 595.926 143.493 596.177 142.401C596.429 141.309 598.042 140.704 598.754 141.57C598.153 140.956 597.42 140.488 596.611 140.201C595.801 139.913 594.937 139.815 594.084 139.913C593.23 140.01 592.411 140.301 591.687 140.764C590.963 141.227 590.355 141.848 589.908 142.582C587.644 139.188 585.24 136.852 581.774 139.006C578.371 141.122 578.415 143.736 574.756 143.108C573.035 142.813 572.872 146.063 571.697 147.354C571.154 148.04 570.447 148.579 569.642 148.923C568.837 149.266 567.959 149.402 567.088 149.32C565.373 148.995 564.061 146.858 565.022 145.4L560.979 154.624L559.35 152.625C556.761 154.313 557.611 158.716 560.217 160.376C562.824 162.037 566.21 161.682 569.212 160.946C572.824 160.06 576.554 159.75 580.263 160.029C585.93 160.83 589.876 167.604 587.778 172.928C589.955 170.79 593.982 172.564 595.11 175.399C596.238 178.234 595.411 181.429 594.575 184.363" fill="#2F2E41"/>
                </g>
                </g>
                <g id="logo">
                <path id="page title" d="M151.596 44H107.404C106.236 44 105.116 44.5268 104.29 45.4645C103.464 46.4022 103 47.6739 103 49C103 50.3261 103.464 51.5979 104.29 52.5355C105.116 53.4732 106.236 54 107.404 54H151.596C152.764 54 153.884 53.4732 154.71 52.5355C155.536 51.5979 156 50.3261 156 49C156 47.6739 155.536 46.4022 154.71 45.4645C153.884 44.5268 152.764 44 151.596 44Z" fill="#EDEDED"/>
                <rect id="img" x="62" y="36" width="28" height="27" rx="6" fill="#EE3838"/>
                </g>
                </g>
                <defs>
                <clipPath id="clip0_8_2">
                <rect width="693" height="528" fill="white"/>
                </clipPath>
                </defs>
              </svg>
            </div>

          </div>
        </div>
        
        <svg className="section__waves" xmlns="http://www.w3.org/2000/svg" viewBox="0 24 150 28 " preserveAspectRatio="none">
              <defs>
                <path id="wave-path" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"> </path>
              </defs>
              <g className="wave1">
                <use href="#wave-path" x="50" y="3" fill="rgba(255,255,255, .1)"> </use>
              </g>
              <g className="wave2">
                <use href="#wave-path" x="50" y="0" fill="rgba(255,255,255, .2)"> </use>
              </g>
              <g className="wave3">
                <use href="#wave-path" x="50" y="9" fill="#fff"> </use>
              </g>
        </svg>

        <a className="scroll-down" href="#about">
          <span className="scroll-down__arrow"></span>
          <img className="scroll-down__img" src="/mouse.svg" alt="" />
        </a>

      </section>

      {/* About me */}
      <section id="about" className="section">
        <div className="container">
          <SectionTitle title="O mnie"/>
          <div className="row gy-4" data-aos="fade-up">
            <div className="col-12 col-lg-4 d-none d-lg-block">
              <img src="/pixel.gif" data-src="/about.jpg" className="img-fluid img-animated rounded lazy" height="400" width="400" alt="Maciej Tymosiak - Programista freelancer, Web Developer" />
            </div>
            <div className="col-12 col-lg-8 d-flex align-items-center">
              <div>
                <h3>Zajmuję się tworzeniem <strong className="text-primary">stron internetowych</strong></h3>
                <hr />
                <p>Nazywam się <strong>Maciej Tymosiak</strong> i w branży jestem od 2019 roku. Jestem samoukiem, a pierwszy staż i pracę dostałem po kilku miesiącach intensywnego zdobywania wiedzy.</p>
                <p>Tworzone przeze mnie strony wyróżniają się wysoką jakością, ze względu na dbałość o szczegóły, <strong>optymalizację</strong>, <strong>bezpieczeństwo</strong> i stosowanie <strong>dobrych praktyk</strong>.</p>
                <Link to="/o-mnie" className="btn btn-secondary" title="Dowiedz się więcej o mnie">Zobacz więcej</Link>
              </div>
            </div>
          </div>
        </div>
{/* 
        <svg className="wave wave--is-bottom" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path fill="#2438b1" fillOpacity="1"
                d="M0,128L80,154.7C160,181,320,235,480,266.7C640,299,800,309,960,314.7C1120,320,1280,320,1360,320L1440,320L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z">
          </path>
        </svg> */}
      </section>

      {/* Offer */}
      <section id="offer" className="section section--with-bg section--with-svg">
        <div className="container">
        <SectionTitle title="Usługi" subtitle="Strona internetowa to podstawowe i w większości jedno z najważniejszych narzędzi w prowadzeniu biznesu. Stworzona przeze mnie strona, przygotowana do potrzeb Twojej firmy, pozwoli Ci zaistnieć w internecie i w świadomości klientów." />

          <div className="row gy-4 services">
            <div className="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
              <div className="services__item">
                <div className="services__icon-container">
                  <svg className="services__icon-background" width="100" height="100" viewBox="0 0 600 600"
                    xmlns="http://www.w3.org/2000/svg">
                    <path stroke="none" strokeWidth="0" fill="#f5f5f5"
                      d="M300,521.0016835830174C376.1290562159157,517.8887921683347,466.0731472004068,529.7835943286574,510.70327084640275,468.03025145048787C554.3714126377745,407.6079735673963,508.03601936045806,328.9844924480964,491.2728898941984,256.3432110539036C474.5976632858925,184.082847569629,479.9380746630129,96.60480741107993,416.23090153303,58.64404602377083C348.86323505073057,18.502131276798302,261.93793281208167,40.57373210992963,193.5410806939664,78.93577620505333C130.42746243093433,114.334589627462,98.30271207620316,179.96522072025542,76.75703585869454,249.04625023123273C51.97151888228291,328.5150500222984,13.704378332031375,421.85034740162234,66.52175969318436,486.19268352777647C119.04800174914682,550.1803526380478,217.28368757567262,524.383925680826,300,521.0016835830174">
                    </path>
                  </svg>
                  <FontAwesomeIcon icon={faFileCode} className="services__icon" />
                </div>
                <h4 className="services__title">Tworzenie stron</h4>
                <p className="services__desc">Stworzę dla Ciebie profesjonalną stronę przystosowaną pod SEO</p>
                <span className="services__price">Od 800 zł/brutto</span>
                <div>
                  <Link to="/brief" className="btn btn-secondary" title="Wyceń swoje potrzeby">
                    Wypełnij brief
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="150">
              <div className="services__item">
                <div className="services__icon-container">
                  <svg className="services__icon-background" width="100" height="100" viewBox="0 0 600 600"
                    xmlns="http://www.w3.org/2000/svg">
                    <path stroke="none" strokeWidth="0" fill="#f5f5f5"
                      d="M300,582.0697525312426C382.5290701553225,586.8405444964366,449.9789794690241,525.3245884688669,502.5850820975895,461.55621195738473C556.606425686781,396.0723002908107,615.8543463187945,314.28637112970534,586.6730223649479,234.56875336149918C558.9533121215079,158.8439757836574,454.9685369536778,164.00468322053177,381.49747125262974,130.76875717737553C312.15926192815925,99.40240125094834,248.97055460311594,18.661163978235184,179.8680185752513,50.54337015887873C110.5421016452524,82.52863877960104,119.82277516462835,180.83849132639028,109.12597500060166,256.43424936330496C100.08760227029461,320.3096726198365,92.17705696193138,384.0621239912766,124.79988738764834,439.7174275375508C164.83382741302287,508.01625554203684,220.96474134820875,577.5009287672846,300,582.0697525312426">
                    </path>
                  </svg>
                  <FontAwesomeIcon icon={faImage} className="services__icon" />
                </div>
                <h4 className="services__title">Projektowanie stron</h4>
                <p className="services__desc">Wykonam dla Ciebie projekt graficzny strony do zakodowania</p>
                <span className="services__price">Od 150 zł/brutto</span>
                <div>
                  <Link to="/brief" className="btn btn-secondary" title="Wyceń swoje potrzeby">
                    Wypełnij brief
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="250">
              <div className="services__item">
                <div className="services__icon-container">
                  <svg className="services__icon-background" width="100" height="100" viewBox="0 0 600 600"
                    xmlns="http://www.w3.org/2000/svg">
                    <path stroke="none" strokeWidth="0" fill="#f5f5f5"
                      d="M300,541.5067337569781C382.14930387511276,545.0595476570109,479.8736841581634,548.3450877840088,526.4010558755058,480.5488172755941C571.5218469581645,414.80211281144784,517.5187510058486,332.0715597781072,496.52539010469104,255.14436215662573C477.37192572678356,184.95920475031193,473.57363656557914,105.61284051026155,413.0603344069578,65.22779650032875C343.27470386102294,18.654635553484475,251.2091493199835,5.337323636656869,175.0934190732945,40.62881213300186C97.87086631185822,76.43348514350839,51.98124368387456,156.15599469081315,36.44837278890362,239.84606092416172C21.716077023791087,319.22268207091537,43.775223500013084,401.1760424656574,96.891909868211,461.97329694683043C147.22146801428983,519.5804099606455,223.5754009179313,538.201503339737,300,541.5067337569781">
                    </path>
                  </svg>
                  <FontAwesomeIcon icon={faGear} className="services__icon" />
                </div>
                <h4 className="services__title">Usługi programisty</h4>
                <p className="services__desc">Posiadasz już własną stronę? Chętnie wykonam poprawki itp.</p>
                <span className="services__price">100 zł/godzina</span>
                <div>
                  <Link to="/brief" className="btn btn-secondary" title="Wyceń swoje potrzeby">
                    Wypełnij brief
                  </Link>
                </div>
              </div>
            </div>

          </div>
      </div>
      <svg className="section__svg-footer" viewBox="0 0 1600 600" xmlns="http://www.w3.org/2000/svg" version="1.1"><path d="M0 68L38.2 78.7C76.3 89.3 152.7 110.7 228.8 114.3C305 118 381 104 457.2 105.8C533.3 107.7 609.7 125.3 685.8 140.8C762 156.3 838 169.7 914.2 169.3C990.3 169 1066.7 155 1142.8 148.7C1219 142.3 1295 143.7 1371.2 137.3C1447.3 131 1523.7 117 1561.8 110L1600 103L1600 601L1561.8 601C1523.7 601 1447.3 601 1371.2 601C1295 601 1219 601 1142.8 601C1066.7 601 990.3 601 914.2 601C838 601 762 601 685.8 601C609.7 601 533.3 601 457.2 601C381 601 305 601 228.8 601C152.7 601 76.3 601 38.2 601L0 601Z" fill="#2438b1"></path><path d="M0 239L38.2 237.8C76.3 236.7 152.7 234.3 228.8 256.8C305 279.3 381 326.7 457.2 340.2C533.3 353.7 609.7 333.3 685.8 307C762 280.7 838 248.3 914.2 252C990.3 255.7 1066.7 295.3 1142.8 323.8C1219 352.3 1295 369.7 1371.2 357.2C1447.3 344.7 1523.7 302.3 1561.8 281.2L1600 260L1600 601L1561.8 601C1523.7 601 1447.3 601 1371.2 601C1295 601 1219 601 1142.8 601C1066.7 601 990.3 601 914.2 601C838 601 762 601 685.8 601C609.7 601 533.3 601 457.2 601C381 601 305 601 228.8 601C152.7 601 76.3 601 38.2 601L0 601Z" fill="#2a3eb5"></path><path d="M0 487L38.2 489C76.3 491 152.7 495 228.8 476.8C305 458.7 381 418.3 457.2 422.5C533.3 426.7 609.7 475.3 685.8 479.2C762 483 838 442 914.2 435.3C990.3 428.7 1066.7 456.3 1142.8 459.3C1219 462.3 1295 440.7 1371.2 449.7C1447.3 458.7 1523.7 498.3 1561.8 518.2L1600 538L1600 601L1561.8 601C1523.7 601 1447.3 601 1371.2 601C1295 601 1219 601 1142.8 601C1066.7 601 990.3 601 914.2 601C838 601 762 601 685.8 601C609.7 601 533.3 601 457.2 601C381 601 305 601 228.8 601C152.7 601 76.3 601 38.2 601L0 601Z" fill="#2f44b9"></path></svg>
    </section>

    <section className="section section--with-fullwidth-wave">
      <div className="container">
        <div className="row">
            <div className="col-12 col-md-8" data-aos="fade-left">
                <h3 className="inner-section__title">Dlaczego powinienem wykonać dla Ciebie stronę internetową?</h3>
                <div className="d-flex align-items-center">
                  <div>
                    <p><strong>Inwestycja w stronę internetową może okazać się skarbonką bez dna, jeśli jej stworzenie zlecisz nieodpowiednim osobom</strong>. Ciągle pojawiające się błędy, ataki hakerskie kończące się zawirusowaną stroną, a nawet znikomy przyrost nowych klientów — to najpowszechniejsze skutki nieodpowiedniego wyboru.</p>
                    <p><strong>Ja do swojej pracy podchodzę z pasją i zaangażowaniem</strong>, jednocześnie oferując atrakcyjne ceny moich usług.</p>
                    <p>Do każdego nowego projektu podchodzę kreatywnie, z dbałością o jakość i estetykę. Mam ambicję, aby każda nowa strona była lepsza od poprzedniej i przy każdym zleceniu, chcę nauczyć się czegoś nowego.</p>
                    <p><strong>Dzięki mnie Twoja strona będzie atrakcyjna, czytelna, zabezpieczona przed wirusami i pozbawiona rażących błędów.</strong> Jeśli jakiś błąd się pojawi to wystarczy mnie o tym poinformować, a przez pół roku po oddaniu strony, bezpłatnie naprawię swoje niedopatrzenie.</p>
                    
                    <div>
                      <Link to="/brief" className="btn btn-secondary btn--with-icon mt-3" title="Wycena">
                        <FontAwesomeIcon icon={faRocket} />
                        Wypełnij brief
                      </Link>
                    </div>
                  </div>
                </div>
            </div>
            <div className="col-12 col-md-4 d-none d-md-flex align-items-center">
              <img className="img-fluid lazy" src="/pixel.gif" data-src="/join.svg" alt="" height="400" />
            </div>
        </div>
      </div>
      <svg className="wave wave--is-bottom wave--full-width" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" preserveAspectRatio="none"><path fill="currentColor" d="M0,96L60,90.7C120,85,240,75,360,106.7C480,139,600,213,720,218.7C840,224,960,160,1080,138.7C1200,117,1320,139,1380,149.3L1440,160L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path></svg>
    </section>

    {/* Portfolio */}
    <section id="portfolio" className="section section--with-bg section--with-svg">
      <div className="container" data-aos="fade-up">
        <SectionTitle title="Portfolio" />
        <Portfolio />
      </div>
      <SvgFooter />
    </section>

    {/* Pricing */}
    <section id="pricing" className="section">
      <div className="container">
        <SectionTitle title="Cennik" subtitle="Poniższe ceny są tylko orientacyjne. Każdy projekt jest wyceniony indywidualnie w zależności od potrzeb." />
        <Pricing />
      </div>
    </section>

    <CtaSection hasBackground />

    <section className="section">
      <div className="container">

        <SectionTitle 
          title="Kontakt" 
          subtitle="Masz pytania? Napisz do mnie. Przed wyceną i rozpoczęciem współpracy, chętnie odpowiem na każde pytanie."
        />

        <div className="row gy-5 gy-lg-0">

          <div className="col-12 col-md-6" data-aos="fade-left">
            <ContactForm />
          </div>

          <div className="col-12 col-md-6 d-flex align-items-center justify-content-center" data-aos="fade-right">
            <img src="/pixel.gif" data-src="/contact.svg" className="img-fluid lazy" height="430" width="636" alt="Potrzebujesz strony internetowej? Napisz do mnie" />
          </div>

        </div>
      </div>
    </section>
    </Layout>
  )
}
